<template>
  <v-app id="viewpoint">
    <v-main>
      <v-row class="login-wrapper">
        <v-col
          cols="12"
          sm="4"
          md="5"
          lg="5"
          xl="5"
          class="d-none d-sm-block d-md-block d-lg-block d-xl-block"
          v-bind:style="leftPanelStyle"
        ></v-col>
        <v-col>
          <v-row
            class="text-center text-sm-left text-md-left text-lg-left text-xl-left login-wrapper"
            align="center"
            justify="center"
          >
            <v-col cols="10" sm="10" md="8" lg="8" xl="8">
              <v-alert
                :color="devNotice.color"
                text
                dense
                icon="mdi-wrench"
                class="mb-12"
                v-if="devNotice.show"
                >{{ devNotice.text }}</v-alert
              >
              <img alt :src="mainLogo" width="200" class="mb-9" />
              <h1>Welcome to Viewpoint</h1>
              <p class="mb-7">Please login to continue</p>
              <v-form @submit.prevent="login" method="post" class="mb-3">
                <v-alert type="error" v-if="hasError">
                  Sorry, something went wrong when attempting to log you in.
                  Please check your details and try again
                </v-alert>
                <v-text-field
                  label="Username"
                  name="email"
                  prepend-icon="mdi-account"
                  type="email"
                  v-model="data.body.email"
                ></v-text-field>
                <v-text-field
                  id="password"
                  label="Password"
                  name="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  v-model="data.body.password"
                ></v-text-field>
                <v-btn
                  color="primary"
                  type="submit"
                  block
                  large
                  :loading="this.$store.getters.showLoader"
                  >Login</v-btn
                >
              </v-form>
              <p class="caption">
                <router-link :to="{ name: 'reset-password' }"
                  >Forgotten Password?</router-link
                >
              </p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
export default {
  props: {
    source: String,
  },

  data() {
    return {
      drawer: null,

      context: "login context",

      data: {
        body: {
          email: null,
          password: null,
        },
        rememberMe: false,
        fetchUser: true,
      },

      hasError: false,

      devNotice: {
        show: false,
        color: null,
        text: null,
      },
    };
  },

  mounted() {
    if (process.env.VUE_APP_STAGE === "local") {
      this.devNotice.show = true;
      this.devNotice.color = "red";
      this.devNotice.text = "Local";
    } else if (process.env.VUE_APP_STAGE === "development") {
      this.devNotice.show = true;
      this.devNotice.color = "blue";
      this.devNotice.text = "Development";
    } else if (process.env.VUE_APP_STAGE === "staging") {
      this.devNotice.show = true;
      this.devNotice.color = "green";
      this.devNotice.text = "Staging";
    }
  },

  computed: {
    leftPanelStyle() {
      return {
        backgroundImage: `url('${window.location.origin}/img/${this.$store.getters.brand.background}')`,
        backgroundColor: "#212121",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      };
    },
    mainLogo() {
      return `${window.location.origin}/img/${this.$store.getters.brand.logo}`;
    },
  },

  methods: {
    login: function() {
      let email = this.data.body.email;
      let password = this.data.body.password;

      this.$store
        .dispatch("login", { email, password })
        .then(() => {
          this.$router.push({ name: "index" });
          this.hasError = false;
        })
        .catch(() => {
          this.hasError = true;
        });
    },
  },
};
</script>

<style>
.login-wrapper {
  height: 100%;
}
</style>
